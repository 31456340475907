<template>
  <div>
    <div class="page_background home_bg" />
    <div v-for="(ext, index) in extList" :key="`${index}_ext`">
      <template v-if="extType(ext, 'banner')">
        <BannersHomeSlider v-if="ext.banner_type === 'slider'" :ext="ext" />
        <BannersTwoInRow v-else-if="ext.banner_type === 'two_row'" :ext="ext" />
        <BannersBigCategories v-else-if="ext.banner_type === 'grid'" :ext="ext" />
        <BannersBrands v-else-if="ext.banner_type === 'four_row'" :ext="ext" />
        <BannersUsers v-else-if="ext.banner_type === 'one_row'" :ext="ext" />
        <InfoCardsBig v-else-if="ext.banner_type === 'three_row'" :ext="ext" />
      </template>
      <render-on-scroll v-else-if="extType(ext, 'product')" :offset-y="100">
        <CarouselProducts :ext="ext" />
      </render-on-scroll>
      <render-on-scroll v-else-if="extType(ext, 'categories')" :offset-y="100">
        <CarouselPopularProducts :ext="ext" />
      </render-on-scroll>
      <render-on-scroll v-else-if="extType(ext, 'post')" :offset-y="100">
        <CarouselPosts :ext="ext" />
      </render-on-scroll>
      <template v-else-if="extType(ext, 'seo')">
        <SeoBlock :ext="ext" />
      </template>
    </div>
  </div>
</template>

<script>
import RenderOnScroll from 'nuxt-render-on-scroll'
import BannersHomeSlider from '@/components/modules/BannersHomeSlider'
import BannersBigCategories from '@/components/modules/BannersBigCategories'
import BannersUsers from '@/components/modules/BannersUsers'
import InfoCardsBig from '@/components/modules/InfoCardsBig'
import CarouselProducts from '@/components/modules/CarouselProducts'
import CarouselPopularProducts from '@/components/modules/CarouselPopularProducts'
import CarouselPosts from '@/components/modules/CarouselPosts'
import SeoBlock from '@/components/modules/SeoBlock'
import BannersBrands from '@/components/modules/BannersBrands'
import BannersTwoInRow from '@/components/modules/BannersTwoInRow'

export default {
  components: {
    SeoBlock,
    CarouselProducts,
    CarouselPopularProducts,
    CarouselPosts,
    BannersHomeSlider,
    BannersUsers,
    BannersBigCategories,
    InfoCardsBig,
    BannersTwoInRow,
    BannersBrands,
    RenderOnScroll
  },
  transition: {
    name: 'intro',
    afterLeave (el) {
      this.$nuxt.$emit('mm_hide')
    }
  },
  async asyncData ({ app }) {
    try {
      const page = await app.$axios.$get('/api/promo/home/')
      return {
        page: page.data
      }
    } catch (err) {
      return {
        page: {
          extensions: []
        }
      }
    }
  },
  data () {
    return {
      page: null
    }
  },
  head () {
    const i18nHead = this.$formatSeoTags(
      this.$nuxtI18nHead({ addSeoAttributes: { canonicalQueries: ['page'] } }),
      'enabled',
      (process.env.API_URL_BROWSER + this.$nuxt.$route.fullPath)
    )
    return {
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      title: this.page.meta_title,
      meta: [
        { hid: 'description', name: 'description', content: this.page.meta_description },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:title', content: this.page.meta_title },
        { property: 'og:description', content: this.page.meta_description },
        { property: 'og:url', content: process.env.API_URL_BROWSER + this.$nuxt.$route.fullPath },
        { property: 'og:image', content: process.env.API_URL_BROWSER + '/icons/logo.svg' },
        { hid: 'twitter_card', property: 'twitter:card', content: 'summary' },
        { hid: 'twitter_title', property: 'twitter:title', content: this.page.meta_title },
        { hid: 'twitter_description', property: 'twitter:description', content: this.page.meta_description },
        { hid: 'twitter_image', property: 'twitter:image', content: process.env.API_URL_BROWSER + '/icons/logo.svg' },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    }
  },
  computed: {
    extList () {
      return this.page.extensions?.length ? this.page.extensions.slice().sort((a, b) => a.position - b.position) : this.page.extensions
    }
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  },
  methods: {
    extType (ext, type) {
      return ext.type === type
    }
  }
}
</script>
